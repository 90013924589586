
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display:none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display:none;
}


:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --base-font-size: 16px;
  --font-size-xs: calc(var(--base-font-size) * 0.75);
  --font-size-sm: calc(var(--base-font-size) * 0.875);
  --font-size-base: var(--base-font-size);
  --font-size-lg: calc(var(--base-font-size) * 1.125);
  --font-size-xl: calc(var(--base-font-size) * 1.25);
  --font-size-2xl: calc(var(--base-font-size) * 1.5);
  --font-size-3xl: calc(var(--base-font-size) * 1.875);
  --font-size-4xl: calc(var(--base-font-size) * 2.25);
  --font-size-5xl: calc(var(--base-font-size) * 3);
  --font-size-6xl: calc(var(--base-font-size) * 4);
  --font-size-9px: calc(var(--base-font-size) * 0.562);
  --font-size-10px: calc(var(--base-font-size) * 0.625);
  --font-size-11px: calc(var(--base-font-size) * 0.687);
  --font-size-13px: calc(var(--base-font-size) * 0.812);
  --font-size-15px: calc(var(--base-font-size) * 0.937);
  --font-size-17px: calc(var(--base-font-size) * 1.062);
  --font-size-19px: calc(var(--base-font-size) * 1.187);
  --font-size-21px: calc(var(--base-font-size) * 1.312);
  --font-size-22px: calc(var(--base-font-size) * 1.375);
  --font-size-26px: calc(var(--base-font-size) * 1.625);
  --font-size-29px: calc(var(--base-font-size) * 1.812);
  --font-size-32px: calc(var(--base-font-size) * 2);
  --font-size-33px: calc(var(--base-font-size) * 2.062);
}


html, body {
  overflow: hidden;
  height: -webkit-fill-avaliable;
}

body {
  height: 100vh;
}

.text-xs {
  font-size: var(--font-size-xs);
}

.text-sm {
  font-size: var(--font-size-sm);
}

.text-base {
  font-size: var(--font-size-base);
}

.text-lg {
  font-size: var(--font-size-lg);
}

.text-xl {
  font-size: var(--font-size-xl);
}

.text-2xl {
  font-size: var(--font-size-2xl);
}

.text-3xl {
  font-size: var(--font-size-3xl);
}

.text-4xl {
  font-size: var(--font-size-4xl);
}

.text-5xl {
  font-size: var(--font-size-5xl);
}

.text-6xl {
  font-size: var(--font-size-6xl);
}

.text-9px {
  font-size: var(--font-size-9px);
}

.text-10px {
  font-size: var(--font-size-10px);
}

.text-11px {
  font-size: var(--font-size-11px);
}

.text-13px {
  font-size: var(--font-size-13px);
}

.text-15px {
  font-size: var(--font-size-15px);
}

.text-17px {
  font-size: var(--font-size-17px);
}

.text-19px {
  font-size: var(--font-size-19px);
}

.text-21px {
  font-size: var(--font-size-21px);
}

.text-22px {
  font-size: var(--font-size-22px);
}

.text-26px {
  font-size: var(--font-size-26px);
}

.text-29px {
  font-size: var(--font-size-29px);
}

.text-32px {
  font-size: var(--font-size-32px);
}

.text-33px {
  font-size: var(--font-size-33px);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

* {
  font-family: 'Helvetica Neue', sans-serif !important;
  -webkit-touch-callout: none;
  /* Safari */
  -webkit-user-select: none;
  /* Chrome */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* scrollbar-color: "#90A0B9"; */
}

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.skiptranslate>iframe {
  display: none !important;
}

.skiptranslate>span {
  display: none !important;
}

#goog-gt-tt {
  display: none !important;
}

body {
  top: 0 !important;
}

.message-button-arrow-left:before {
  content: "";
  position: absolute;
  top: -7px;
  left: -6px;
  z-index: 1;
  border: solid 6px transparent;
  transform: rotate(135deg);
}

.message-button-arrow-right:before {
  content: "";
  position: absolute;
  bottom: -7px;
  right: -6px;
  z-index: 1;
  border: solid 6px transparent;
  transform: rotate(-45deg);
}

/* scrollbar vertical */
.scrollbar-thin::-webkit-scrollbar {
  width: 4px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #9F9F9F;
  border-radius: 10px;
  background-clip: content-box;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 0px 4px !important;
  border-radius: 10px;
}

/* scrollbar horizontal */
.scrollbar-thin::-webkit-scrollbar:horizontal {
  height: 3px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:horizontal {
  background-color: #9F9F9F;
  border-radius: 10px;
  background-clip: content-box;
}

.scrollbar-thin::-webkit-scrollbar-track:horizontal {
  background-color: transparent;
  margin: 4px 0px !important;
  border-radius: 10px;
}

/* scrollbar horizontal invisible */
.scrollbar-invisible::-webkit-scrollbar {
  height: 0;
  display: none;
}

.scrollbar-invisible {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-invisible::-webkit-scrollbar-thumb:horizontal,
.scrollbar-invisible::-webkit-scrollbar-track:horizontal {
  display: none;
}

.menu-bg-with-gradient-premium {
  background-image: linear-gradient(to bottom, rgba(3, 10, 19, 0) 0%, rgba(3, 10, 19, 0.92) 8%, rgba(3, 10, 19, 0.95) 11%, rgba(3, 10, 19, 0.88) 79%, rgba(3, 10, 19, 0.68) 92%, rgba(3, 10, 19, 0) 100%), url('../../public//background/menu.svg');
  background-size: contain;
}

.menu-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 78, 203, 0) 0%, rgba(0, 78, 203, 0.92) 8%, rgba(0, 78, 203, 0.95) 11%, rgba(0, 78, 203, 0.88) 79%, rgba(0, 78, 203, 0.68) 92%, rgba(0, 78, 203, 0) 100%), url('../../public//background/menu.svg');
  background-size: contain;
}

.schedule-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(2, 70, 147, 0) 0%, rgba(0, 78, 203, 0.92) 8%, rgba(0, 78, 203, 0.95) 11%, rgba(0, 78, 203, 0.88) 79%, rgba(1, 75, 182, 0.68) 92%, rgba(2, 70, 147, 0) 100%), url('../../public//background/menu.svg');
  background-size: contain;
}

.schedule-bg-with-gradient-premium {
  background-image: linear-gradient(to bottom, rgba(3, 10, 19, 0) 0%, rgba(3, 10, 19, 0.92) 8%, rgba(3, 10, 19, 0.95) 11%, rgba(3, 10, 19, 0.88) 79%, rgba(3, 10, 19, 0.68) 92%, rgba(3, 10, 19, 0) 100%), url('../../public//background/menu.svg');
  background-size: contain;
}

.speed-networking-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 78, 203, 0) 0%, rgba(0, 78, 203, 0.92) 20%, rgba(0, 78, 203, 0.95) 25%, rgba(0, 78, 203, 0.88) 79%, rgba(0, 78, 203, 0.68) 92%, rgba(0, 78, 203, 0) 100%), url('../../public//background/menu.svg');
  background-size: contain;
}

.speed-networking-bg-with-gradient-premium {
  background-image: linear-gradient(to bottom, rgba(3, 10, 19, 0) 0%, rgba(3, 10, 19, 0.92) 20%, rgba(3, 10, 19, 0.95) 25%, rgba(3, 10, 19, 0.88) 79%, rgba(3, 10, 19, 0.68) 92%, rgba(3, 10, 19, 0) 100%), url('../../public//background/menu.svg');
  background-size: contain;
}

.profileoverview-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 72, 188, 0.6) 0%, rgba(0, 72, 188, 0.92) 23%, rgba(0, 72, 188, 0.95) 35%, rgba(0, 72, 188, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: cover;
}

.profileoverview-bg-with-gradient-premium {
  background-image: linear-gradient(to bottom, rgba(3, 10, 19, 0.6) 0%, rgba(3, 10, 19, 0.92) 23%, rgba(3, 10, 19, 0.95) 35%, rgba(3, 10, 19, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: cover;
}

.meeting-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 72, 188, 0.6) 0%, rgba(0, 72, 188, 0.92) 23%, rgba(0, 72, 188, 0.95) 35%, rgba(0, 72, 188, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: cover;
}

.meeting-bg-with-gradient-premium {
  background-image: linear-gradient(to bottom, rgba(3, 10, 19, 0.6) 0%, rgba(3, 10, 19, 0.92) 23%, rgba(3, 10, 19, 0.95) 35%, rgba(3, 10, 19, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: cover;
}

.handsfree-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 72, 188, 0.6) 0%, rgba(0, 72, 188, 0.92) 10%, rgba(1, 40, 101, 0.95) 40%, rgba(1, 40, 101, 0.95) 60%, rgba(1, 75, 182, 0.95) 90%, rgba(0, 72, 188, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: cover;
}

.chat-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(6, 30, 62, 0.5) 0%, rgba(0, 0, 0, 1) 100%), url('../../public//background/menu.svg') !important;
  background-size: cover !important;
}

.meeting-record-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(2, 70, 147, 0.06) 0%, rgba(1, 40, 101, 0.8) 35%, rgba(1, 40, 101, 0.9) 50%, rgba(1, 40, 101, 0.8) 65%, rgba(2, 70, 147, 0.06) 100%);
  background-size: cover;
}

.meeting-record-bg-with-gradient-premium {
  background-image: linear-gradient(to bottom, rgba(3, 10, 19, 0.06) 0%, rgba(5, 27, 55, 0.8) 35%, rgba(5, 27, 55, 0.9) 50%, rgba(5, 27, 55, 0.8) 65%, rgba(3, 10, 19, 0.06) 100%);
  background-size: cover;
}

.authentication-bg {
  background-image: url('../../public/background/authentication.svg');
  background-size: contain;
}

.dark_pattern_bg1 {
  background-image: url('../../public/background/dark-pattern-bg1.svg');
  background-size: contain;
}

.chat-bg {
  background-image: url('../../public/background/authentication.svg');
}

.messagecard-bg-premium {
  background: linear-gradient(#FF9E1B, #FFFFFF61 , #FF9E1B), #FF9E1B;
}

.videoplayer-controls {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(6, 30, 62, 0.62) 20%, rgba(6, 30, 62, 0.62) 100%);
}

.dashboard-bg {
  background-image: url('../../public/background/dashboard2.png');
  background-size: contain;
}

.dashboard-bg-with-gradient-premium {
  background-image: linear-gradient(to bottom, rgba(3, 10, 19, 0.6) 0%, rgba(3, 10, 19, 0.92) 23%, rgba(3, 10, 19, 0.95) 35%, rgba(3, 10, 19, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: cover;
}

.dashboard-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 78, 203, 0.6) 0%, rgba(0, 78, 203, 0.92) 23%, rgba(0, 78, 203, 0.95) 35%, rgba(0, 78, 203, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: cover;
}

.default-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 78, 203, 0.95) 0%, rgba(1, 75, 182, 0.9) 90%, rgba(2, 70, 147, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: contain;
}

.qrcode-bg-with-gradient-premium {
  background-image: linear-gradient(to bottom, rgba(3, 10, 19, 0.6) 0%, rgba(3, 10, 19, 0.92) 23%, rgba(3, 10, 19, 0.95) 35%, rgba(3, 10, 19, 0.95) 100%), url('../../public//background/menu.svg');
  background-size: contain;
}

.profile-bg-with-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 72, 188, 1) 0%, rgba(0, 72, 188, 1) 50%, rgba(0, 78, 203, 0) 100%), url('../../public//background/profile.svg');
  background-size: cover;
}

.schedule-bg {
  background-image: linear-gradient(to bottom, rgba(0, 78, 203, 0.06) 0%, rgba(0, 78, 203, 0.9) 5%, rgba(0, 78, 203, 0.95) 55%, rgba(0, 78, 203, 0.90) 95%, rgba(0, 78, 203, 0.06) 100%), url('../../public//background/menu.svg');
}

.overview-profile-bg {
  background: linear-gradient(180deg, rgb(255, 255, 255) 81.5%, rgba(255, 255, 255, 0.97) 100%);
}

.overview-profile-bg-premium {
  background: linear-gradient(180deg, rgb(6, 30, 62) 81.5%, rgba(6, 30, 62, 0.97) 100%);
}

.shadow-default {
  box-shadow: 0px 3px 6px 0px rgba(192, 201, 226, 0.38);
}

.fade {
  -webkit-mask-image: linear-gradient(180deg, #000 60%, #000 70%, transparent);
}

.goog-te-combo {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  outline: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.goog-te-combo:hover {
  background-color: #e0e0e0;
  border-color: #aaa;
}

.goog-te-combo:focus {
  background-color: #fff;
  border-color: #666;
}

.goog-te-combo option {
  background-color: #fff;
  color: #333;
}

.recording {
  color: red !important;
  animation: blink 0.7s alternate infinite ease-in;
}

.qr-reader {
  width: 330px;
  height: 50dvh;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-reader .qr-box .qr-frame {
  position: absolute;
}

.highlight-static {
  position: absolute;
  border-radius: 50%;
  background-color: #4E92FF61;
  animation: ping-static 1.5s cubic-bezier(0, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

.highlight-animation {
  position: absolute;
  border-radius: 50%;
  background-color: #4E92FF61;
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  background: #fff;
  border-radius: 5px;
  outline: none;
  opacity: 0.8;
  transition: opacity 0.15s ease-in-out;
}

.custom-range:hover {
  opacity: 1;
}

/* WebKit (Chrome, Safari, etc.) */
.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #061E3E;
  border: 2px solid white;
  cursor: pointer;
  margin-top: -6px;
}

.custom-range::-webkit-slider-runnable-track {
  background: #fff;
  border-radius: 5px;
  height: 4px;
}

/* Firefox */
.custom-range::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

.custom-range::-moz-range-track {
  background: #fff;
  border-radius: 5px;
  height: 4px;
}

.container {
  background: lightblue;
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    auto;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.deck {
  position: absolute;
  width: 300px;
  height: 200px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.deck > div {
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 150px;
  height: 85vh;
  max-height: 285px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

.animate-flash {
  position: relative;
  overflow: hidden;
}

.animate-flash::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 70%);
  animation: flash 1.5s infinite;
}

@keyframes flash {
  0% {
    transform: translateX(-100%) translateY(-100%);
  }
  100% {
    transform: translateX(100%) translateY(100%);
  }
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  75% {
    transform: scale(2);
    opacity: 1;
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes ping-static {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 1;
  }
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.swipe {
  position: absolute;
  width: calc(100vw - 60px);
}

.swipe-card {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.ptr__children {
  overflow-x: visible !important;
}